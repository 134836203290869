<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Year</h1>
          <v-form ref="investmentForm">
            <v-label>Year</v-label>
            <v-text-field
              v-model="year"
              outlined
              placeholder="Year"
              class="mt-1"
            ></v-text-field>

            <v-btn @click="validate" v-if="!loading" large>Add Year</v-btn>
            <v-btn  v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "AddBrand",
  data: () => ({
    myCroppa: {},
    year: undefined,
    loading:false
  }),
  methods: {
    ...mapActions(['postYear','getSingleYear']),
    async validate() {
      this.loading = true;
     let data = {};
     if(this.year != undefined){
       data.yearName = this.year;
     }
      if (this.$route.params.id != undefined) {
        data._id =  this.$route.params.id;
      }
      await this.postYear(data);
      if(this.allYear.status == 200 || this.allYear.status == 201){
        this.$router.push("/business/years");
      }
      this.loading = false;
    },
   
  },
  computed:{
    ...mapGetters(['allYear'])
  },
  async created() {
    if (this.$route.params.id) {
      await this.getSingleYear(this.$route.params.id);
      this.year = this.allYear.results.yearName;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>